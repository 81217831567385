
















































































import { Binding } from '../binding'
import BindingEditor from '@reporting/components/BindingEditor.vue'
import BindingSuggest from '@reporting/components/BindingSuggest.vue'
import DlgHeader from '@components/DlgHeader.vue'
import ModalWrapper from '@components/ModalWrapper.vue'
import { dlg } from '@utils/dlgUtils'
const BindingDlg = {
	name: 'BindingDlg',
	props: {
		prop: {
			type: Object,
			required: true,
		},
		cell: {
			type: Object,
			required: true,
		},
	},
	components: {
		BindingSuggest,
		DlgHeader,
		ModalWrapper,
		BindingEditor,
	},
	data() {
		return {
			showSuggest: '', // '', 'methods', or 'values'
			insertText: '',
			binding: undefined,
		}
	},
	computed: {
		suggestTop() {
			const button =
				this.showSuggest === 'methods' ? this.$refs.methodsButton : this.$refs.valuesButton
			return button.offsetTop + button.offsetHeight
		},
		suggestLeft() {
			const button =
				this.showSuggest === 'methods' ? this.$refs.methodsButton : this.$refs.valuesButton
			return button.offsetLeft
		},
	},
	created() {
		this.binding = new Binding(this.cell)
	},
	methods: {
		toggleSuggest(type) {
			if (this.showSuggest === type) this.showSuggest = ''
			else this.showSuggest = type
		},
		cancel() {
			dlg.close(this, true)
		},
		close() {
			dlg.close(this, true, this.binding)
		},
	},
}

export default BindingDlg

export function openBindingDlg(cell, prop): Promise<Binding> {
	return dlg.open(BindingDlg, { cell, prop })
}
