























































































import DlgHeader from '@components/DlgHeader.vue'
import ModalWrapper from '@components/ModalWrapper.vue'
import { dlg } from '@utils/dlgUtils'
import { Template, TemplateSet, ReportDetail } from '../classes'
import { openContextDlg } from '../dialogs/ContextDlg.vue'
import { ContextMeta, getPreviewContext } from '../context'
import Vue from 'vue'
import reportService from '@services/reportService'
import PdfViewer from '@components/PdfViewer.vue'
import { getLocale } from '@utils/locale'
import { user } from '@services/user'
import consultations from '@services/consultationService'

function getSample(parent, child) {
	if (!child) return ContextMeta[parent].sample

  let result
  if (ContextMeta[parent].items[child]) {
    result = ContextMeta[parent].items[child].sample
  }

	let p = previewData.contextValues[parent]
	if (p) {
		result = p[child] || result
	}
	return result
}

let previewData: IPdfTemplatePreview = {
	templateType: undefined,
	set: undefined,
	consultantId: undefined,
	userId: undefined,
	contextValues: {},
	requestValues: {},
	responseValues: {},
	reportValues: {},
	culture: undefined,
}

const PreviewDlg = {
	name: 'PreviewDlg',
	props: ['set'],
	components: {
		DlgHeader,
		ModalWrapper,
		PdfViewer,
	},
	data() {
		return {
			isAdmin: false,
			userId: null,
			users: [],
			consultantId: null,
			consultants: [],
			pdfData: null,
			template: null,
			showMobile: false,
			showDark: this.$store.getters.themeIsDark,
		}
	},
	async created() {
		let set: TemplateSet = this.set
		let type = reportService.template.type
		this.template = set.templates.find(t => t.type === type)

		this.userId = user.claims.userId
		this.consultantId = set.consultantId

		this.isAdmin = user.permissions.serverAdministration
		if (this.isAdmin) {
			if (set.isStandard) {
				this.users = await user.getAllUsers()
			} else {
				let consultants = await consultations.getConsultantGroupMembers(set.consultantId)
				let setConsultant = consultants.find(c => c.id === set.consultantId)
				if (!setConsultant) {
					setConsultant = this.$store.state.static.consultants.find(c => c.id === set.consultantId)
					consultants.splice(0, 0, setConsultant)
				}
				this.consultants = consultants
			}
		}
	},
	watch: {
		template: function(template: Template, prev: Template) {
			this.pdfData = null

			if (prev != null && !prev.isPdfTemplate) {
				prev.saveValues()
				savePreviewTemplateData(prev)
				copyReportContextValuesToPreviewContext(previewData.contextValues, prev.report)
			}

			if (template.isPdfTemplate) {
				this.loadPdf()
			} else {
				let context = getPreviewContext()
				template.hydrate(context)
				template.report.setActiveTemplate(template)
			}
		},
		showDark(showDark) {
			const root = this.$refs.preview
			this.$store.dispatch('applyTheme', { mode: showDark ? 'dark' : 'light', root })
		},
	},
	methods: {
		loadPdf() {
			this.pdfData = null
			let data: IPdfTemplatePreview = {
				templateType: this.template.type,
				set: this.set.toJSON(),
				consultantId: this.consultantId,
				userId: this.userId,
				contextValues: getPreviewContext(['Consultant', 'FinalizedBy']),
				requestValues: previewData.requestValues,
				responseValues: previewData.responseValues,
				reportValues: previewData.reportValues,
				culture: getLocale(),
			}

			for (let prop in previewData.contextValues) {
				data.contextValues[prop] = previewData.contextValues[prop]
			}

			reportService.downloadPdfTemplatePreview(data).then(r => {
				this.pdfData = new Uint8Array(r.data)
				previewData = data
			})
		},
		save() {
			let values = this.template.saveValues()
			let json = JSON.stringify(values, null, 4)
			alert(json)
		},
		close() {
			let prev: Template = this.template
			if (prev && !prev.isPdfTemplate) {
				savePreviewTemplateData(prev)
				copyReportContextValuesToPreviewContext(previewData.contextValues, prev.report)
			}
			dlg.close(this)
		},
		openContextDlg,
		async editContext() {
			let newContext = await openContextDlg(this.template.root.context)
			this.template.setContext(newContext)
		},
	},
}

function savePreviewTemplateData(t: Template) {
	if (t.type === 'Request') {
		previewData.requestValues = t.values
	} else if (t.type === 'Response') {
		previewData.responseValues = t.values
	} else if (t.type === 'Report') {
		previewData.reportValues = t.values
	}
}

function copyReportContextValuesToPreviewContext(previewContext: ValueMap, report: ReportDetail) {
	let patient = (previewContext['Patient'] = {})
	patient['Birthdate'] = report.patient.birthdate
	patient['Breed'] = report.patient.breed
	patient['Gender'] = report.patient.gender
	patient['Species'] = report.patient.species
	patient['Owner'] = report.patient.owner
	patient['Weight'] = report.patient.weight
	patient['WeightUnit'] = report.patient.weightUnit
	patient['PatientId'] = report.patient.id
	patient['Name'] = report.patient.name

	let consultant = (previewContext['Consultant'] = {})
	consultant['Address'] = report.consultant.address
	consultant['Email'] = report.consultant.email
	consultant['Name'] = report.consultant.name
	consultant['Organization'] = report.consultant.organization
	consultant['Phone'] = report.consultant.phone
	consultant['Website'] = report.consultant.website

	let client = (previewContext['ReferredBy'] = {})
	client['Address'] = report.client.address
	client['City'] = report.client.city
	client['State'] = report.client.state
	client['PostalCode'] = report.client.postalCode
	client['Email'] = report.client.email
	client['Name'] = report.client.name
	client['Organization'] = report.client.organization
	client['Phone'] = report.client.phone
}

export default PreviewDlg

export function openPreviewDlg(json: ITemplateSet) {
	json.templates.forEach(t => (t.meta = reportService.meta))
	let set = TemplateSet.load(json)

	// hack to get around Vue bug
	let vm = new Vue({
		data: {
			set,
		},
	})
	let report = new ReportDetail()
	report.consultants = []
	report.billingCodes = [
		{ id: '1', name: 'Sample 1', value: 'SAMPLE-1' },
		{ id: '2', name: 'Sample 2', value: 'SAMPLE-2' },
	]
	report.priorities = [{ name: 'Standard', value: 0 }, { name: 'STAT', value: 1 }]
	report.services = [{ name: 'Normal', id: '0' }, { name: 'Expedited', id: '1' }]

	report.patient = {
		birthdate: getSample('Patient', 'Birthdate'),
		breed: getSample('Patient', 'Breed'),
		gender: getSample('Patient', 'Gender'),
		name: getSample('Patient', 'Name'),
		owner: getSample('Patient', 'Owner'),
		id: getSample('Patient', 'PatientId'),
		species: getSample('Patient', 'Species'),
		weight: getSample('Patient', 'Weight'),
		weightUnit: 'KGS',
	}

	report.consultant = {
		id: null,
		type: null,
		isImageOptional: null,
		city: getSample('Consultant', 'City'),
		state: getSample('Consultant', 'State'),
		address: getSample('Consultant', 'Address'),
		email: getSample('Consultant', 'Email'),
		name: getSample('Consultant', 'Name'),
		organization: getSample('Consultant', 'Organization'),
		clinicCode: getSample('Consultant', 'ClinicCode'),
		phone: getSample('Consultant', 'Phone'),
		website: getSample('Consultant', 'Website'),
	}

	report.images = []
	const imageComment = {
		htmlValue: getSample('ImageComment', 'Comment'),
		textValue: '',
		editDateTime: getSample('ImageComment', 'EditDateTime'),
		reportImageId: '',
		imageId: '',
		order: 0,
		acquisitionDate: getSample('ImageComment', 'AcquisitionDate'),
		anatomy: getSample('ImageComment', 'Anatomy'),
		limb: getSample('ImageComment', 'Limb'),
		url: '',
		view: getSample('ImageComment', 'View'),
	}
	report.imageComments = [imageComment]
	const addendum = {
		fromUserDetail: getSample('Addendum', 'AddedBy'),
		textValue: '',
		htmlValue: getSample('Addendum', 'Message'),
		editDateTime: getSample('Addendum', 'DateTime'),
	}
	report.addendums = [addendum]
	report.attachments = []
	report.relatedReports = []

	report.client = {
		id: null,
		label: null,
		address: getSample('ReferredBy', 'Address'),
		city: getSample('ReferredBy', 'City'),
		state: getSample('ReferredBy', 'State'),
		postalCode: getSample('ReferredBy', 'PostalCode'),
		email: getSample('ReferredBy', 'Email'),
		name: getSample('ReferredBy', 'Name'),
		organization: getSample('ReferredBy', 'Organization'),
		phone: getSample('ReferredBy', 'Phone'),
		clinic: null,
	}
	report.standard = set.getTemplate('Report')
	report.request = set.getTemplate('Request')
	report.response = set.getTemplate('Response')
	report.setActiveTemplate(set.templates[0])
	report.canRespond = true
	report.isPreview = true
	set.templates.forEach(t => {
		t.report = report
		if (previewData) {
			if (t.type === 'Request') t.values = previewData.requestValues
			if (t.type === 'Response') t.values = previewData.responseValues
			if (t.type === 'Report') t.values = previewData.reportValues
		}
	})
	return dlg.open(PreviewDlg, { set })
}
