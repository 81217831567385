




















import DlgHeader from '@components/DlgHeader.vue'
import ModalWrapper from '@components/ModalWrapper.vue'
import { dlg } from '@utils/dlgUtils'
import state from '@services/reportService'
import { TemplateSet } from '../classes'

const SaveReportAsDlg = {
	name: 'SaveReportAsDlg',
	props: ['set'],
	data() {
		return { setName: '' }
	},
	components: {
		DlgHeader,
		ModalWrapper,
	},
	methods: {
		save() {
			if (!this.setName || !this.setName.length) return
			dlg.close(this, true, this.setName)
		},
		close() {
			dlg.close(this, false)
		},
	},
	created() {
		this.setName = this.set.name
	},
}

export default SaveReportAsDlg

export function openSaveAsDlg(set: TemplateSet) {
	return dlg.open(SaveReportAsDlg, { set })
}
