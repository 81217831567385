<template>
	<v-select :value="color" :options="colorOptions" @input="$emit('input', $event)">
		<template slot="selected-option" slot-scope="{ label: colorOption }">
			<div
				v-if="colorOption"
				class="color-swatch"
				:data-color="`${getColorVariable(colorOption)}`"
				:style="{ background: `var(--${getColorVariable(colorOption)})` }"
			></div>
		</template>
		<template slot="option" slot-scope="{ label: colorOption }">
			<span v-if="!colorOption">{{ isTextColor ? 'Default' : 'None' }}</span>
			<div v-else class="color-swatch" :style="{ background: `var(--${getColorVariable(colorOption)})` }"></div>
		</template>
	</v-select>
</template>

<script>
export default {
	name: 'ColorPicker',
	model: {
		prop: 'color',
		event: 'input',
	},
	props: {
		color: {
			type: String,
			default: '',
		},
		isTextColor: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			colorOptions: ['', 'primary', 'secondary', 'tertiary', 'info', 'danger', 'warning', 'success'],
		}
	},
	methods: {
		getColorVariable(colorOption) {
			const prefix = this.isTextColor ? 'text' : 'bg'
			return `report-${prefix}-${colorOption}`
		},
	},
}
</script>

<style lang="scss">
.color-swatch {
	min-width: 160px;
	width: 100%;
	height: 1em;
	border: 1px solid var(--secondary-border);
}
</style>
