<template>
	<div class="cell-accordion" :class="{ 'is-expanded': isExpanded }">
		<div
			style="user-select: none; font-weight: 600;"
			@click="expand"
			@mouseenter="state.selCell = cell"
		>
			<svg-icon icon="chevron-right" class="expand-arrow" />
			{{ title }}
		</div>
		<cell-props v-if="isExpanded" :cell="cell" :title="title" />
	</div>
</template>

<script>
import state from '@services/reportService'
import CellProps from './CellProps.vue'
export default {
	name: 'CellAccordion',
	components: { CellProps },
	props: ['cell'],
	data() {
		return {
			state,
		}
	},
	computed: {
		isExpanded() {
			return state.activeCell === this.cell
		},
		title() {
			if (this.cell.isRoot || this.cell.type === 'Layout Wrapper') {
				let title
				if (this.cell.layout === this.cell.template.root) title = this.cell.template.type
				else title = this.cell.layout.name
				return title.replace('Standard ', '')
			}
			if (this.cell.isTableCell) return 'Table Cell'
			return this.cell.type
		},
	},
	methods: {
		expand() {
			state.activeCell = this.cell
		},
	},
}
</script>

<style lang="scss">
.cell-accordion {
	.expand-arrow svg {
		transition: transform 0.1s;
	}
	&:hover {
		color: var(--secondary-label);
	}
	&:not(:hover) {
		color: var(--tertiary-label);
	}
	cursor: pointer;
}
.cell-accordion.is-expanded {
	.expand-arrow svg {
		transform: rotate(90deg);
	}
	color: var(--primary-label);
	cursor: initial;
}
</style>
