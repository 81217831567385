












import modal from '@components/ModalWrapper.vue'
import { dlg } from '@utils/dlgUtils'

const ContextDlg = {
	name: 'ContextDlg',
	props: ['context'],
	data() {
		return {
			json: '',
		}
	},
	components: {
		modal,
	},
	methods: {
		close() {
			dlg.close(this, true, JSON.parse(this.json))
		},
	},
	created() {
		this.json = JSON.stringify(this.context, null, 4)
	},
	mounted() {
		let el: HTMLTextAreaElement = this.$el.querySelector('textarea')
		el.onkeydown = (e: KeyboardEvent) => {
			if (e.keyCode === 9) {
				e.stopPropagation()
				el.value += '\t'
				return false
			}
		}
		el.focus()
	},
}

export default ContextDlg

export function openContextDlg(context: any): Promise<string> {
	return dlg.open(ContextDlg, { context })
}
