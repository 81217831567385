














import state from '@services/reportService'
import { Cell, Panel } from '../classes'
import Layout from './Layout.vue'
import LayoutPageHeader from './LayoutPageHeader.vue'

let main = null
let dropPanel: Panel
let dropIndex: number
let dragEl: HTMLElement

let dropIndicator: HTMLElement = document.createElement('div')
dropIndicator.id = 'dropIndicator'

function onMouseDown(e) {
	window.getSelection().empty()
	if (state.selCell) {
		state.activeCell = state.selCell
	}
}

function onDragEnd(e: DragEvent) {
	state.dragCell = null
}

function onDragOver(e: DragEvent) {
	e.preventDefault()
	if (state.selTool || state.dragCell) updateDropIndicator(e)
}

function onMouseMove(e: MouseEvent) {
	if (state.selTool || state.dragCell) updateDropIndicator(e)
}

function updateDropIndicator(e: MouseEvent) {
	let t = e.target as HTMLElement
	let elCell: any = t.closest('.report-layout .cell')
	if (!elCell) return
	let cell = elCell.__vue__.cell as Cell
	const isPageBreak = state.selTool === 'Page Break' || (state.dragCell && state.dragCell.type === 'Page Break')
	// page breaks can only be dropped into root panels
	if (isPageBreak) dropPanel = cell.layout.root
	else {
		// default to dropping into cell's parent panel (excluding panels that wrap tables)
		dropPanel = cell.panel && cell.panel.type !== 'Table' ? cell.panel : null
		if (cell instanceof Panel && cell.type !== 'Table') {
			// if cell itself is a panel, we will drop into that instead
			dropPanel = cell
			// add some tolerance for dropping into a child panel's parent panel even if zero padding
			if (!cell.isRoot && cell.panel.type !== 'Table') {
				let altPanel = cell.panel
				var rect = cell.el.getBoundingClientRect()
				let diff = e.pageY - rect.top
				if (diff < 5) {
					dropPanel = altPanel
				}
			}
		}
	}
	dropIndex = undefined
	let el: HTMLElement

	function process() {
		for (let i = 0; i < dropPanel.cells.length; i++) {
			let c = dropPanel.cells[i]
			var rect = c.el.getBoundingClientRect()
			let diff = e.pageY - rect.top
			let half = rect.height / 2
			if (diff < half) {
				el = c.el
				dropIndex = c.index
				break
			}
		}
	}

	if (dropPanel && cell) {
		let p = dropPanel.el.querySelector('.panel-cells')
		process()

		const arrow = '8px solid var(--icon-success)'
		const transparent = '8px solid transparent'

		dropIndicator.style.width = p.clientWidth + 'px'
		dropIndicator.style.height = '17px'
		dropIndicator.style.borderTop = transparent
		dropIndicator.style.borderBottom = transparent
		dropIndicator.style.borderLeft = arrow
		dropIndicator.style.borderRight = arrow
		dropIndicator.style.top = el ? `${el.offsetTop - 8}px` : `${p.clientHeight - 8}px`
		dropIndicator.style.left = '0px'

		p.appendChild(dropIndicator)
		state.selCell = dropPanel
	}
}

function onMouseUp(e: MouseEvent) {
	if (state.dragCell) {
		state.dropCell(dropPanel, dropIndex)
		e && e.stopPropagation()
		return false
	}
	if (state.selTool) {
		let cell = state.selLayout.createCell(state.selTool, dropPanel, dropIndex)
		state.selTool = null
		if (cell.def) state.activeCell = cell
	}
}

export default {
	name: 'Editor',
	components: {
		Layout,
	},
	data() {
		return {
			state,
		}
	},
	computed: {
		pageHeaderWrapper() {
			if (this.state.pageHeader) {
				return new Cell('Layout Wrapper', this.state.pageHeader, 'Page Header')
			}
			return null
		},
		pageFooterWrapper() {
			if (this.state.pageFooter) {
				return new Cell('Layout Wrapper', this.state.pageFooter, 'Page Footer')
			}
			return null
		},
	},
	watch: {
		'state.selTool'(newValue) {
			dropIndicator.remove()
		},
		'state.dragCell'(newValue) {
			dropIndicator.remove()
		},
		'state.selLayout': {
			handler(newLayout, oldLayout) {
				const isPdf = l => l && l.template && l.template.type.includes('PDF Report')
				if (isPdf(newLayout) && !isPdf(oldLayout)) this.$store.dispatch('applyTheme', { mode: 'light', root: this.$el })
				if (isPdf(oldLayout) && !isPdf(newLayout))
					this.$store.dispatch('applyTheme', { mode: 'light', root: this.$el, unsetTheme: true })
			},
			immediate: true,
		},
	},
	beforeDestroy() {
		main.removeEventListener('mousemove', onMouseMove)
		main.removeEventListener('dragover', onDragOver)
		main.removeEventListener('mouseup', onMouseUp)
		main.removeEventListener('mousedown', onMouseDown)
		main.removeEventListener('keydown', this.onKeyDown)
		main.removeEventListener('drop', onMouseUp)
		document.removeEventListener('dragend', onDragEnd)
	},
	mounted() {
		main = this.$el
		main.addEventListener('mousemove', onMouseMove)
		main.addEventListener('dragover', onDragOver)
		main.addEventListener('mouseup', onMouseUp)
		main.addEventListener('mousedown', onMouseDown)
		main.addEventListener('keydown', this.onKeyDown)
		main.addEventListener('drop', onMouseUp)
		document.addEventListener('dragend', onDragEnd)
	},
	methods: {
		onKeyDown(e) {
			const cell = state.activeCell
			if (!cell) return
			let stopPropagation = true
			let cellAbove = null
			let cellBelow = null
			let cellLeft = null
			let cellRight = null
			if (cell.panel) {
				cellAbove = cell.panel.cellAbove(cell, true)
				cellBelow = cell.panel.cellBelow(cell, true)
				cellLeft = cell.panel.cellLeft(cell)
				cellRight = cell.panel.cellRight(cell)
			} else if (cell.isPanel) {
				cellAbove = (cell as Panel).lastCell
				cellBelow = (cell as Panel).firstCell
			}
			switch (e.key) {
				case 'c':
					if (e.ctrlKey || e.metaKey) state.copy()
					break
				case 'v':
					if (e.ctrlKey || e.metaKey) state.paste()
					break
				case 'Del':
				case 'Delete':
				case 'Backspace':
					if (cell.isRoot || cell.isTableCell) return
					const nextCell = cell.panel.cellBelow(cell, false) || cell.panel.cellAbove(cell, false) || cell.panel
					state.activeCell.remove()
					state.activeCell = nextCell
					// Focus active cell
					if (nextCell) {
						nextCell.el.focus()
					}
					break
				case 'Tab':
					state.activeCell = e.shiftKey ? cellAbove : cellBelow
					break
				case 'ArrowUp':
					state.activeCell = cellAbove || cell
					e.preventDefault()
					break
				case 'ArrowDown':
					state.activeCell = cellBelow || cell
					e.preventDefault()
					break
				case 'ArrowLeft':
					state.activeCell = cellLeft || cell
					e.preventDefault()
					break
				case 'ArrowRight':
					state.activeCell = cellRight || cell
					e.preventDefault()
					break
				case 'Home':
					state.activeCell = cell.panel.firstCell
					break
				case 'End':
					state.activeCell = cell.panel.lastCell
					break
				default:
					stopPropagation = false
					if (stopPropagation) e.stopPropagation()
			}
		},
	},
}
