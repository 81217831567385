




















































































import state from '@services/reportService'
import MappingsMixin from './MappingsMixin'

export default {
	name: 'IncomingMappings',
	mixins: [MappingsMixin],
	computed: {
		availableFields() {
			return this.fields.filter(f => !f.controls.length)
		},
	},
	methods: {
		isControlSelectable(control: ICell): boolean {
			if (!this.selItem) return false
			if (this.selItem.controls.includes(control)) return true
			if (this.fields.some(f => f.controls.includes(control))) return false
			return this.selItem.fieldTypes.includes(control.type)
		},
	},
}
