<template>
	<div id="toolbox" class="side-pane">
		<ul v-for="toolGroup in toolGroups" :key="toolGroup.name">
			<h4>{{ toolGroup.name }}</h4>
			<li
				v-for="tool in toolGroup.tools"
				:key="tool.name"
				draggable="true"
				:class="{ selected: state.selTool === tool.name }"
				:title="tool.description"
				@click="setTool(tool.name)"
				@dragstart="setDragTool($event, tool.name)"
			>
				<span style="display: inline-flex; align-items: baseline;">
					<svg-icon icon="drag-vertical" fixed class="is-muted" style="margin-left: -4px;" />
					<svg-icon v-if="tool.icon" :icon="tool.icon" fixed style="margin-right: 4px;" />
					{{ tool.name }}
				</span>
				<svg-icon v-if="state.selTool === tool.name" icon="chevron-right" />
			</li>
		</ul>
	</div>
</template>

<script>
import state from '@services/reportService'

const isInvalidTemplate = t =>
	t.invalidTemplates && t.invalidTemplates.includes(state.template.type)
const isInvalidLayout = t => {
	return t.invalidLayouts && t.invalidLayouts.includes(state.selLayout.name)
}
const isInvalidControl = t => isInvalidTemplate(t) || isInvalidLayout(t)
const isCommonControl = t => !isInvalidControl(t) && !t.validTemplates
const isFormField = t => isCommonControl(t) && t.isBindable
const isBasicElement = t => isCommonControl(t) && !t.isBindable
const isTemplateControl = t =>
	!isInvalidControl(t) &&
	t.validTemplates &&
	t.validTemplates.includes(state.template.type) &&
	state.selLayout === state.template.root // only apply template whitelist to root layouts
const alphabetize = (a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0)

export default {
	name: 'Toolbox',
	data() {
		return {
			state,
		}
	},
	computed: {
		toolGroups() {
			const formFields = state.tools.filter(isFormField).sort(alphabetize)
			const basicElements = state.tools.filter(isBasicElement).sort(alphabetize)
			const templateControls = state.tools.filter(isTemplateControl).sort(alphabetize)
			return [
				{
					name: 'Basic Elements',
					tools: basicElements,
				},
				{
					name: 'Form Fields',
					tools: formFields,
				},
				{
					name: state.template.type.replace('Standard ', '') + ' Controls',
					tools: templateControls,
				},
			].filter(g => g.tools.length)
		},
	},
	methods: {
		newLayout() {
			state.createNewLayout()
		},
		setDragTool(e, tool) {
			state.selTool = tool
			e.dataTransfer.setData('tool', tool)
		},
		setTool(tool) {
			if (state.selTool === tool) {
				state.selTool = null
			} else {
				state.selTool = tool
			}
		},
	},
}
</script>

<style lang="scss">
#toolbox {
	h4 {
		margin-bottom: 4px;
	}
	ul {
		margin-top: 8px;
	}
	ul + ul {
		margin-top: 24px;
	}
	li {
		display: flex;
		position: relative;
		align-items: center;
		justify-content: space-between;
		background: var(--button-default-bg);
		border: 1px solid var(--divider);
		padding: 8px 4px;
		font-size: 0.9em;
		cursor: grab;
		& + li {
			margin-top: -1px;
		}
		.icon {
			opacity: 0.7;
		}
		&.selected {
			background: var(--button-primary-bg-hover);
			color: var(--button-primary-color);
		}
	}

	button {
		margin-top: 8px;
	}
}
</style>
