





















import DlgHeader from '@components/DlgHeader.vue'
import ModalWrapper from '@components/ModalWrapper.vue'
import Tabs from '@components/Tabs.vue'
import IncomingMappings from './PartnerFieldMapDlg/IncomingMappings.vue'
import OutgoingMappings from './PartnerFieldMapDlg/OutgoingMappings.vue'
import { dlg } from '@utils/dlgUtils'
import state from '@services/reportService'
import { TemplateSet } from '../classes'

interface IMappingTab {
	id: string
	partner: string
	template: string
	dataflow: string // 'incoming' | 'outgoing'
}

const PartnerFieldMapDlg = {
	name: 'PartnerFieldMapDlg',
	data() {
		return {
			state,
			activeTabId: null,
			tabs: [],
		}
	},
	components: {
		DlgHeader,
		IncomingMappings,
		OutgoingMappings,
		ModalWrapper,
		Tabs,
	},
	computed: {
		activeTab() {
			return this.tabs.find(t => t.id === this.activeTabId)
		},
	},
	async created() {
		this.buildTabs()
	},
	methods: {
		buildTabs() {
			const tabs: IMappingTab[] = []
			// create a tab for each partner + template + dataflow
			Object.keys(state.fieldMap).forEach(partner => {
				const partnerFields = state.fieldMap[partner]
				const partnerTemplates = [...new Set(partnerFields.map(f => f.template))]
				partnerTemplates.forEach(template => {
					const partnerTemplateFields = partnerFields.filter(f => f.template === template)
					const partnerTemplateDataflows = [...new Set(partnerTemplateFields.map(f => f.dataflow))]
					partnerTemplateDataflows.forEach(dataflow => {
						tabs.push({
							id: template + partner + dataflow,
							partner,
							template,
							dataflow,
						})
					})
				})
			})
			tabs.sort(sortTabs)
			this.tabs = tabs
			this.activeTabId = tabs[0].id

			function sortTabs(a, b) {
				// Place Request tabs first
				if (a.template < b.template) return -1
				if (b.template < a.template) return 1
				// Then place Incoming tabs before Outgoing
				if (a.dataflow < b.dataflow) return -1
				if (b.dataflow < a.dataflow) return 1
				// Then sort by partner
				if (a.partner < b.partner) return -1
				if (b.partner < a.partner) return 1
				return 0
			}
		},
		close() {
			dlg.close(this)
		},
	},
}

export default PartnerFieldMapDlg

export function openPartnerFieldMapDlg(partner): Promise<TemplateSet> {
	return dlg.open<TemplateSet>(PartnerFieldMapDlg, { partner })
}
