import store from '@store'
import { API } from '@services/api'

class User {
	get claims(): IClaims {
		return store.state.auth.claims
	}

	get permissions(): IPermissions {
		return store.state.static.permissions
	}

	get isAuthenticated(): boolean {
		return !!store.state.auth.token
	}

	getAllUsers(): Promise<IBasicUser> {
		return API.get(`/user/all-users`).then(r => r.data)
	}
}

export const user = new User()
