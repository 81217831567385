<template>
	<form
		class="report-table-column-editor"
		style="display: flex; flex-direction: column;"
		@submit.prevent="updateColumns"
	>
		<div
			v-for="(col, i) in columns"
			:key="i"
			style="display:flex; align-items:center; margin-bottom:4px;"
		>
			<input
				v-model.number="newWidths[i]"
				class="input"
				:class="{
					'is-changed': newWidths[i] !== col,
					'is-invalid': newWidths[i] < 10 || newWidths[i] > maxWidth,
				}"
				type="number"
			/>
			<span style="padding: 0 24px 0 4px;">%</span>
			<button
				class="btn btn-default btn-delete"
				style="margin-left: 4px;"
				:disabled="columns.length === 1"
				type="button"
				@click="deleteColumn(i)"
			>
				<svg-icon icon="close" />
			</button>
		</div>
		<transition name="expand">
			<div
				v-if="isPendingChange"
				style="display: flex; flex-direction: column; margin-bottom: 8px;"
			>
				<p
					v-for="error in validationErrors"
					:key="error"
					style="text-align: center; padding: 4px 0; font-size: 0.9em;"
				>
					<svg-icon icon="exclamation-triangle" class="is-warning" />
					{{ error }}
				</p>
				<button
					:disabled="validationErrors.length > 0"
					class="btn btn-success"
					type="button"
					@click="updateColumns"
				>
					<svg-icon icon="check" />
					Apply Changes
				</button>
				<button class="btn btn-default" type="button" @click="resetWidths">
					<svg-icon icon="undo" />
					Discard Changes
				</button>
			</div>
		</transition>
		<button
			class="btn btn-default"
			type="button"
			:disabled="columns.length >= 10"
			@click="addColumn"
		>
			<svg-icon icon="plus-circle-outline" />
			Add Column
		</button>
	</form>
</template>

<script>
export default {
	name: 'ColumnEditor',
	props: {
		cell: {
			type: Object,
			required: true,
		},
		value: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {
			newWidths: [],
		}
	},
	computed: {
		columns: {
			get() {
				return this.value
			},
			set(columns) {
				this.$emit('input', columns)
			},
		},
		isPendingChange() {
			return this.newWidths.some((w, i) => w !== this.columns[i])
		},
		maxWidth() {
			return 100 - (this.value.length - 1) * 10
		},
		validationErrors() {
			const MIN_MAX_ERROR = `Percentages must be between 10% and ${this.maxWidth}% for ${
				this.columns.length
			} columns.`
			const SUM_ERROR = 'Percentages must total 100%.'
			let errors = []
			const isInvalid = w => w < 10 || w > this.maxWidth
			if (this.newWidths.some(isInvalid)) errors.push(MIN_MAX_ERROR)
			if (this.newWidths.reduce((a, b) => a + b, 0) !== 100) errors.push(SUM_ERROR)
			return errors
		},
	},
	watch: {
		value: {
			handler() {
				this.resetWidths()
			},
			immediate: true,
		},
	},
	methods: {
		addColumn() {
			if (this.columns.length >= 10) return
			this.columns = [...this.columns, 10]
		},
		resetWidths() {
			this.newWidths = [...this.value]
		},
		updateColumns() {
			if (this.validationErrors.length) return
			this.newWidths.forEach((w, i) => {
				this.cell.setColumnWidth(this.columns, i, w) // TODO
			})
			this.columns = [...this.newWidths]
		},
		deleteColumn(index) {
			if (this.columns.length === 1) return
			const removeColumnCells = c => {
				if (c.props.col === index) c.remove()
			}
			const shiftHigherColumnCellsToTheLeft = c => {
				if (c.props.col > index) c.props.col = c.props.col - 1
			}
			this.cell.cells.forEach(removeColumnCells)
			this.cell.cells.forEach(shiftHigherColumnCellsToTheLeft)
			this.columns.splice(index, 1)
			this.columns = this.columns
		},
	},
}
</script>

<style>
.report-table-column-editor .is-changed {
	box-shadow: inset 3px 0 0 var(--icon-warning);
}
.report-table-column-editor .is-changed.is-invalid {
	box-shadow: inset 3px 0 0 var(--icon-danger);
}
</style>
