<template>
	<section id="reportEditorMenu">
		<ast-toolbar :is-primary="true" style="align-items: center;">
			<toolbar-branding />
			<toolbar-button icon="arrow-left" @click.native="goBack()" />
			<h3 style="margin: 0 0 0 1em">Template Editor</h3>
			<span style="flex-grow:1"></span>
			<help-menu :items="helpItems" />
			<user-menu />
		</ast-toolbar>

		<ast-toolbar v-if="state.set">
			<div style="display: flex; align-items: center; font-size: 0.9em;">
				<status-tag :status="set.status" :is-legacy="set.isLegacy" style="margin-left: 8px;" />
				<span style="padding: 8px;">Template Set</span>
				<input
					v-model.trim="state.set.name"
					:disabled="set.isActive"
					placeholder="Name"
					class="input"
					style="width: 200px; margin-right: 8px;"
				/>
			</div>
			<toolbar-button icon="folder-open-outline" label="Open" @click.native="openSettings()" />
			<toolbar-button
				v-if="set.isActive && set.type !== 'Teleconsultation' && !set.isLegacy"
				:disabled="!!state.set.warnings.length || isLoading"
				icon="stop-circle"
				label="Retire"
				@click.native="retire()"
			/>
			<toolbar-button
				:disabled="set.isActive || !hasUnsavedChanges || isLoading"
				icon="floppy-o"
				label="Save"
				@click.native="save"
			/>
			<toolbar-button icon="save-copy" label="Save Copy" :disabled="isLoading" @click.native="saveAs" />
			<toolbar-button
				v-if="!set.isActive || isTeleconsultation"
				:disabled="set.isActive || !!state.set.warnings.length || isLoading"
				icon="check-circle"
				label="Publish"
				@click.native="setActive()"
			/>
			<undo-redo />
			<toolbar-button
				icon="copy"
				label="Copy"
				:disabled="isLoading || !state.activeCell"
				title="Copy (Ctrl+C)"
				@click.native="state.copy"
			/>
			<toolbar-button
				icon="content-paste"
				label="Paste"
				:disabled="isLoading || !state.canPaste"
				title="Paste (Ctrl+V)"
				@click.native="state.paste"
			/>
			<toolbar-button icon="eye" label="Preview" @click.native="openPreviewDlg()" />
			<toolbar-button
				v-if="hasPartnerMappings && isTeleconsultation"
				icon="link"
				label="Map Fields"
				@click.native="openPartnerFieldMapDlg"
			/>
			<toolbar-button
				v-if="state.set.warnings.length"
				class="is-warning"
				icon="exclamation-triangle"
				:label="warningCount"
				@click.native="showWarnings()"
			/>
			<span style="flex-grow: 1"></span>
			<div
				v-if="setConsultant"
				style="display: flex; flex-wrap: wrap; align-items: center; font-size: 0.8em; padding: 16px;"
			>
				<span class="is-muted">Editing for </span>
				<svg-icon icon="account" fixed />
				{{ setConsultant }}
			</div>
		</ast-toolbar>
		<tabs v-if="state.set" :tabs="tabs" :value="state.selLayout" accent @input="state.selectLayout($event)" />
	</section>
</template>

<script>
import state from '@services/reportService'
import { openSettings } from '@dialogs/Settings.vue'
import { openPreviewDlg } from '../dialogs/PreviewDlg.vue'
import AstToolbar from '@components/Toolbar.vue'
import ToolbarButton from '@components/ToolbarButton.vue'
import ToolbarBranding from '@components/ToolbarBranding.vue'
import UserMenu from '@components/UserMenu.vue'
import StatusTag from './StatusTag.vue'
import UndoRedo from './UndoRedo.vue'
import { openSaveAsDlg } from '@reporting/dialogs/SaveReportAsDlg.vue'
import { showErrors } from '../../dialogs/MessageDlg.vue'
import { openPartnerFieldMapDlg } from '@reporting/dialogs/PartnerFieldMapDlg.vue'
import { showConfirm } from '@dialogs/ConfirmDlg.vue'
import { TemplateSetStatus } from '@reporting/classes'
import HelpMenu from '@/components/HelpMenu.vue'
import Tabs from '@/components/Tabs.vue'

export default {
	name: 'Menu',
	components: {
		AstToolbar,
		StatusTag,
		ToolbarBranding,
		ToolbarButton,
		UserMenu,
		UndoRedo,
		HelpMenu,
		Tabs,
	},
	data() {
		return {
			state,
			isLoading: false,
			helpItems: [],
      hasUnsavedChanges: false
		}
	},
	computed: {
		tabs() {
			const tabs = []
			if (this.state.set) {
				this.state.set.templates.forEach(template => {
					tabs.push({
						id: template.type,
						value: template.root,
						label: template.type.replace('Standard ', ''),
					})
				})
			}
			this.state.openLayouts.forEach(layout => {
				tabs.push({
					id: layout.name + layout.template.id,
					value: layout,
					label: layout.name,
					onClose: () => state.closeLayout(layout),
				})
			})
			return tabs
		},
		set() {
			return state.set
		},
		setConsultant() {
			if (!state.set) return ''
			const consultantId = state.set.consultantId
			if (consultantId === this.$store.state.auth.claims.userId) return ''
			const consultant = this.$store.state.static.consultants.find(c => c.id === consultantId)
			if (!consultant) return ''
			return consultant.name
		},
		hasPartnerMappings() {
			return Object.keys(state.fieldMap).length
		},
		isTeleconsultation() {
			return this.set.type === 'Teleconsultation'
		},
		warningCount() {
			let count = state.set.warnings.length
			if (count === 1) {
				return '1 Warning'
			} else {
				return `${count} Warnings`
			}
		},
	},
  watch: {
    'state.hasUnsavedChanges': {
      handler(hasUnsavedChanges) {
        this.hasUnsavedChanges = hasUnsavedChanges
      }
    }
  },
	methods: {
		openPartnerFieldMapDlg,
		goBack() {
			this.$router.push({
				path: '/',
				query: state.prevRoute && state.prevRoute.query,
			})
		},
		async setActive() {
			if (state.set.warnings.length) return
			let message = 'Are you sure you want to publish this template set?'
			if (this.isTeleconsultation) {
				message += ' Any currently published set will become retired.'
			}
			if (!(await showConfirm(message))) return
			if (!this.isTeleconsultation) {
				const sets = await state.getTemplateSets(true)
				const publishedSetWithSameName = sets.find(s => {
					return s.name.trim() === this.set.name.trim() && s.status === TemplateSetStatus.Published
				})
				if (publishedSetWithSameName) {
					const message =
						`There is already a published template set named ${state.set.name}. It ` +
						`must be retired in order to publish your new template set.  Would you like to retire ` +
						`that set now?`
					if (!(await showConfirm(message))) return
					await state.setTemplateSetStatus(publishedSetWithSameName, TemplateSetStatus.Retired)
				}
			}
			if (this.set.status === TemplateSetStatus.Draft) await this.save()
			await state.setTemplateSetStatus(this.set, TemplateSetStatus.Published)
		},
		async retire() {
			if (this.isTeleconsultation) return
			if (!(await showConfirm('Are you sure you want to retire this template set?'))) return
			await state.setTemplateSetStatus(this.set, TemplateSetStatus.Retired)
		},
		async save() {
			await state.saveTemplateSet(this.state.set.toJSON())
		},
		async saveAs() {
			try {
				let newName = await openSaveAsDlg(this.set)
				this.isLoading = true
				let json = this.set.toJSON()
				json.name = newName
				await state.saveTemplateSetAs(json)
				this.$router.push({
					name: 'report-editor',
					params: { setId: json.id },
				})
			} finally {
				this.isLoading = false
			}
		},
		openSettings() {
			let settingView = 'teleconsultation'
			if (this.set && this.set.type !== 'Teleconsultation') settingView = 'reports'
			openSettings(settingView)
		},
		openPreviewDlg() {
			openPreviewDlg(state.set.toJSON())
		},
		showWarnings() {
			showErrors(state.set.warnings)
		},
	},
}
</script>

<style lang="scss">
#reportEditorMenu {
	.toolbar .separator {
		width: 0;
		margin: 0 6px;
		border-right: 1px solid var(--divider);
	}
}
</style>
