















































































import state from '@services/reportService'
import MappingsMixin from './MappingsMixin'

export default {
	name: 'OutgoingMappings',
	mixins: [MappingsMixin],
	computed: {
		availableControls() {
			const isUnassigned = c => this.fields.every(f => !f.controls.includes(c))
			return this.controls.filter(isUnassigned)
		},
	},
	methods: {
		isFieldSelectable(field: IPartnerField): boolean {
			if (!this.selItem) return false
			return field.fieldTypes.includes(this.selItem.type)
		},
		moveControlUp(field: IPartnerField, control: ICell) {
			const index = field.controls.indexOf(control)
			if (index === 0) return
			field.controls.splice(index - 1, 0, field.controls.splice(index, 1)[0])
		},
		moveControlDown(field: IPartnerField, control: ICell) {
			const index = field.controls.indexOf(control)
			if (index === field.controls.length - 1) return
			field.controls.splice(index + 1, 0, field.controls.splice(index, 1)[0])
		},
	},
}
