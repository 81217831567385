
























import Vue from 'vue'
import state from '@services/reportService'
import CellAccordion from './components/CellAccordion.vue'
import Toolbox from './components/Toolbox.vue'
import TopMenu from './components/Menu.vue'
import Editor from './components/Editor.vue'
import { showConfirm } from '@dialogs/ConfirmDlg.vue'
import { openSettings } from '@dialogs/Settings.vue'
import { eventBus } from '@services/eventBus'

const unsavedChangesWarning = 'You have unsaved changes.  Are you sure you want to leave without saving?'

export default Vue.extend({
	name: 'ReportEditor',
	components: {
		CellAccordion,
		Toolbox,
		TopMenu,
		Editor,
	},
	props: {
		setId: {
			type: String,
			required: true,
		},
	},
	data() {
		return {
			state,
			isLoadingSet: false,
		}
	},
	computed: {
		cellChain() {
			let result = []
			let cell = this.state.activeCell
			while (cell) {
				result.push(cell)
				cell = cell.panel
			}
			return result.reverse()
		},
	},
	watch: {
		setId: {
			async handler() {
				if (this.setId && (!state.set || this.setId !== state.set.id)) {
					state.openLayouts = []
					this.isLoadingSet = true
					const set = await state.getTemplateSet(this.setId)
					state.loadTemplateSet(set)
				}
			},
			immediate: true,
		},
		'state.set.name'() {
			this.updateDocumentTitle()
		},
		'state.hasUnsavedChanges': {
			handler(hasUnsavedChanges) {
				const toggle = hasUnsavedChanges ? window.addEventListener : window.removeEventListener
				toggle('beforeunload', this.confirmUnload)
				this.updateDocumentTitle()
			},
			immediate: true,
		},
	},
	beforeDestroy() {
		state.closeTemplate()
		state.resetStateStorage()
		window.removeEventListener('beforeunload', this.confirmUnload)
	},
	async beforeRouteLeave(to, from, next) {
		if (!this.$store.getters.isAuthenticated) return next() // if logout already confirmed, leave
		if (await this.confirmNavigation()) {
			let dlg = to.query.dlg
			let settingView = to.query.settingView
			if (dlg && settingView) {
				eventBus.once('routechanged', () => {
					openSettings(settingView)
				})
			}
			next() // confirm leaving if unsaved changes
		} else {
			next(false)
		}
	},
	async beforeRouteUpdate(to, from, next) {
		if (to.params.setId === from.params.setId) return next()
		if (await this.confirmNavigation()) return next()
		next(false)
	},
	beforeRouteEnter(to, from, next) {
		state.prevRoute = from
		next()
	},
	methods: {
		openSettings() {
			openSettings()
		},
		updateDocumentTitle() {
			if (!state.set || !state.set.name) return
			document.title = `${state.hasUnsavedChanges ? '*' : ''}${state.set.name} - Keystone Omni`
		},
		confirmNavigation() {
			if (!state.set || !state.hasUnsavedChanges) return true
			return showConfirm(unsavedChangesWarning)
		},
		confirmUnload(e) {
			e.returnValue = unsavedChangesWarning
			return unsavedChangesWarning
		},
	},
})
