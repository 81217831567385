








































import { dlg } from '@utils/dlgUtils'
import DlgHeader from '@components/DlgHeader.vue'
import ModalWrapper from '@components/ModalWrapper.vue'
import RichText from '../primitives/RichText.vue'
import { Cell } from '../classes'
import { showConfirm } from '@/dialogs/ConfirmDlg.vue'
import { openPromptDlg } from '@/dialogs/TextPromptDlg.vue'
import { showAlert } from '@/dialogs/MessageDlg.vue'

const RichTextPresets = {
	name: 'RichTextPresets',
	components: {
		DlgHeader,
		ModalWrapper,
		RichText,
	},
	props: ['cell'],
	data() {
		return {
			isRefreshing: false,
			selectedPreset: null,
		}
	},
	methods: {
		async selectPreset({ preset, currentValue }) {
			await this.trySaveChanges()
			this.selectedPreset = preset
		},
		async trySaveChanges(value) {
			if (!value) value = this.cell.value
			if (this.selectedPreset && this.selectedPreset.value !== value) {
				const message = `Do you want to save changes to "${this.selectedPreset.label}"?`
				const shouldSave = await showConfirm(message, {
					confirmText: 'Save changes',
					cancelText: 'Discard',
					showCloseHeader: false,
					title: 'Save changes',
				})
				if (shouldSave) this.savePreset(value)
			}
		},
		savePreset(value) {
			if (!this.selectedPreset) return
			this.selectedPreset.value = value || this.cell.value
			if (!this.cell.props.presets) this.cell.props.presets = []
			const presetIndex = this.cell.props.presets.findIndex(p => p.label === this.selectedPreset.label)
			this.cell.props.presets.splice(presetIndex, 1, this.selectedPreset)
		},
		async deletePreset() {
			if (await showConfirm(`Are you sure you want to delete "${this.selectedPreset.label}"?`)) {
				const presetIndex = this.cell.props.presets.findIndex(p => p.label === this.selectedPreset.label)
				this.cell.props.presets.splice(presetIndex, 1)
				this.selectedPreset = null
				this.refreshEditor()
			}
		},
		async addPreset() {
			const label = await openPromptDlg({
				title: 'New Preset',
				prompt: 'Name (shown in Preset menu):',
				requireInput: true,
			})
			if (!label) return
			if (this.cell.props.presets.some(p => p.label === label)) {
				await showAlert('A preset with that name already exists.')
				return this.addPreset()
			}
			this.cell.props.presets.push({
				label,
				value: this.cell.value,
			})
			this.refreshEditor(label)
		},
		refreshEditor(labelToSelect) {
			// toggle the RichText v-if for a tick to force it to rebuild toolbar
			this.isRefreshing = true
			this.$nextTick(() => {
				this.isRefreshing = false
				if (labelToSelect) {
					this.$nextTick(() => {
						// select newly added preset from dropdown
            setTimeout(() => {
              const presetButton = this.$el.querySelector('button.tox-tbtn.tox-tbtn--select[aria-label="Presets"]')
              if (presetButton) {
                presetButton.click()
                setTimeout(() => {
                  const option = document.querySelector(`div[title="${labelToSelect}"]`)
                  // @ts-ignore
                  if (option) option.click()
                }, 10)
              }
            },20)
					})
				}
			})
		},
		async close() {
			await this.trySaveChanges()
			this.cell.value = null
			dlg.close(this)
		},
	},
}

export default RichTextPresets

export function openRichTextPresets(cell: Cell) {
	return dlg.open(RichTextPresets, { cell })
}
