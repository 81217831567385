<template>
	<div class="option-list-editor">
		<div
			v-for="(option, i) in list"
			:key="i"
			style="display:flex; align-items: center; margin-bottom: 4px"
		>
			<input v-model.trim="list[i]" class="input" />
			<button class="btn btn-default" :disabled="i === 0" title="Move up" @click="moveItemUp(i)">
				<svg-icon icon="caret-up" />
			</button>
			<button
				class="btn btn-default"
				:disabled="i === list.length - 1"
				title="Move down"
				@click="moveItemDown(i)"
			>
				<svg-icon icon="caret-down" />
			</button>
			<button
				class="btn btn-default btn-delete"
				:disabled="list.length === 1"
				title="Remove option"
				@click="list.splice(i, 1)"
			>
				<svg-icon icon="close" />
			</button>
		</div>
		<form
			style="display: flex; align-items: center; margin-bottom: 4px"
			@submit.prevent="addOption"
		>
			<input v-model.trim="newOption" class="input" placeholder="New option ..." />
			<button class="btn btn-default" type="submit">
				<svg-icon icon="plus-circle-outline" title="Add new option" />
			</button>
		</form>
	</div>
</template>

<script>
export default {
	name: 'ListEditor',
	props: {
		value: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {
			newOption: '',
		}
	},
	computed: {
		list: {
			get() {
				return this.value
			},
			set(list) {
				this.$emit('input', list)
			},
		},
	},
	methods: {
		moveItem({ from, to }) {
			this.list.splice(to, 0, this.list.splice(from, 1)[0])
		},
		moveItemUp(i) {
			this.moveItem({ from: i, to: i - 1 })
		},
		moveItemDown(i) {
			this.moveItem({ from: i, to: i + 1 })
		},
		addOption() {
			this.list.push(this.newOption)
			this.list = [...new Set(this.list)]
			this.newOption = ''
		},
	},
}
</script>

<style>
.option-list-editor input {
	font-size: 0.8em;
}
</style>
