
































import { dlg } from '@utils/dlgUtils'
import DlgHeader from '@components/DlgHeader.vue'
import ImageFileInput from '@components/ImageFileInput.vue'
import ModalWrapper from '@components/ModalWrapper.vue'
import { userData } from '@services/userData'
import { addNotification } from '@services/notificationService'

const ClinicImagesDlg = {
	name: 'ConfirmDlg',
	components: {
		DlgHeader,
		ModalWrapper,
		ImageFileInput,
	},
	data() {
		return {
			images: [],
			selectedUrl: null,
		}
	},
	props: {
		preSelectedUrl: {
			type: String,
			default: null,
		},
	},
	async created() {
		this.images = await userData.getClinicImages()
		this.selectedUrl = this.preSelectedUrl || null
	},
	methods: {
		close() {
			dlg.close(this, true, this.selectedUrl)
		},
		async clearImage(id) {
			await userData.deleteClinicImage(id)
			let found = this.images.find(i => i.id === id)
			if (found.url === this.selectedUrl) {
				this.selectedUrl = null
			}
			this.images = this.images.filter(i => i.id !== id)
		},
		selectImage(id: string) {
			let item = this.images.find(i => i.id === id)
			this.selectedUrl = item && item.url
		},
		changeFile({ target }) {
			if (!target.files.length) return
			let file: File = target.files[0]

			const MAX_SIZE = 10000000 // 10 MB
			if (file.size > MAX_SIZE) {
				addNotification('Images cannot exceed 10 MB', 'error')
				return
			}

			let userImage: IClinicImage = {
				id: undefined,
				clinicCode: undefined,
				filename: undefined,
				image: undefined,
				url: undefined,
			}

			this.images.push(userImage)

			userData.saveClinicImage(userImage, file)
		},
	},
}

export default ClinicImagesDlg

export function openClinicImagesDlg(preSelectedUrl: string) {
	return dlg.open(ClinicImagesDlg, { preSelectedUrl }, false)
}
