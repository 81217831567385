





















































































































































































































































































































































import Vue from 'vue'
import { Cell, Layout, Template } from '../classes'
import { Binding } from '../binding'
import state from '@services/reportService'
import ColorPicker from './ColorPicker.vue'
import ColumnEditor from './ColumnEditor.vue'
import ListEditor from './ListEditor.vue'
import PaddingEditor from './PaddingEditor.vue'
import AstCheckbox from '@components/Checkbox.vue'
import AstRadio from '@components/Radio.vue'
import BindingEditor from '@reporting/components/BindingEditor.vue'
import ReqFields from '@reporting/components/ReqFields.vue'
import { openBindingDlg } from '@reporting/dialogs/BindingDlg.vue'
import { showConfirm } from '@dialogs/ConfirmDlg.vue'
import { showAlert } from '@dialogs/MessageDlg.vue'
import { openClinicImagesDlg } from '@/dialogs/ClinicImagesDlg.vue'
import { openRichTextPresets } from '../dialogs/RichTextPresets.vue'

export default {
	name: 'CellProps',
	components: {
		AstCheckbox,
		AstRadio,
		ColorPicker,
		ColumnEditor,
		ListEditor,
		PaddingEditor,
		BindingEditor,
		ReqFields,
	},
	props: ['cell', 'title'],
	data() {
		return {
			state,
			props: [],
			cellName: null,
			layoutName: null,
		}
	},
	computed: {
		panelList() {
			let result: string[] = []
			let cell: Cell = this.cell
			let templates = state.set.templates.filter(t => t !== state.template)
			templates.forEach(t => {
				let panels = t.root.controls.filter(c => c.isPanel)
				result = panels.map(p => `${t.type}.${p.name}`)
			})
			if (result.length) {
				result.splice(0, 0, null)
			}
			return result
		},
		visibleProps() {
			// hide Border Color prop if this is a Table Cell in a Table with no borders
			const isHiddenBorderProp = prop => {
				if (!this.cell.isTableCell || prop.name !== 'borderColor') return false
				return !this.cell.panel.props.border || this.cell.panel.props.border === 'none'
			}

			return this.props.filter(p => p.type !== 'hidden' && !isHiddenBorderProp(p))
		},
		canDeleteCell() {
			return !this.cell.isRoot && !this.cell.isTableCell && this.cell.type !== 'Layout Wrapper'
		},
		binding() {
			return this.cell.binding || new Binding(this.cell)
		},
		isUserLayout() {
			if (!this.cell.isRoot) return false
			const isUserLayoutForThisCell = l => l.name === this.cell.layout.name && l.group
			return this.cell.template.layouts.some(isUserLayoutForThisCell)
		},
		isPdfTemplateRoot() {
			// show Draft Watermark prop for root PDF panel
			if (!this.cell.isRoot || !this.cell.template.isPdfTemplate) return false
			// do not show on Page Header and Page Footer root panels
			return ![this.cell.template.pageHeader, this.cell.template.pageFooter].includes(this.cell.layout)
		},
		cellMayAppearOnPdf() {
			// only show Private checkbox if the panel could appear on the PDF
			// (i.e. it is either in the PDF Report layout or any request/response selector layout)
			return (this.cell.template.type === 'PDF Report' && !this.cell.isRoot) || !!this.cell.layout.group
		},
		isImageCommentLayout() {
			return this.cell.layout.name === 'Image Comment' && !this.cell.layout.group
		},
		verticalAlignmentOptions() {
			const options = ['top', 'middle', 'bottom']
			// only allow stretch on non-PDF templates due to lack of flexbox support in PDF/email render
			if (!this.cell.template.type.includes('PDF')) options.push('stretch')
			return options
		},
		optionsPropOptions() {
			const optionsProp = this.visibleProps.find(p => p.name === 'options')
			if (!optionsProp) return null
			return optionsProp.value
		},
		canReset() {
			return this.cell.isRoot && this.cell.template.meta.layouts.find(w => !w.group && w.name === this.cell.layout.name)
		},
		imageType: {
			get() {
				let prop = this.props.find(p => p.name === 'source')
				if (!['[Signature]', '[Logo]', '[Image Comment]'].includes(prop.value)) {
					return ''
				} else return prop.value
			},
			set(value) {
				let prop = this.props.find(p => p.name === 'source')
				prop.value = value
			},
		},
	},
	watch: {
		'cell.props.cols'() {
			this.createProps()
		},
		cell: {
			async handler(cell, previousCell) {
				if (previousCell) {
					// ensure any pending rename operations take place
					this.renameCell(previousCell)
					this.renameLayout(previousCell)
				}
				this.layoutName = this.cell.layout.name
				this.cellName = this.cell.name
				this.createProps()
			},
			immediate: true,
		},
	},
	methods: {
		openRichTextPresets,
		updateTable() {
			this.cell.panel.configureTable()
		},
		async editBinding(prop) {
			const binding = await openBindingDlg(this.cell, prop)
			if (binding === undefined) return
			this.cell.binding = binding.text ? binding : null
			prop.value = binding.text
			this.saveProps()
		},
		edit(widgetName) {
			let layout = state.editableLayouts.find(l => l.name === widgetName)
			state.selectLayout(layout)
		},
		changeCellType(type) {
			this.cell.type = type
			this.saveProps()
		},
		deleteCell() {
			const nextCell =
				this.cell.panel.cellBelow(this.cell, false) || this.cell.panel.cellAbove(this.cell, false) || this.cell.panel
			this.cell.remove()
			state.activeCell = nextCell
		},
		refreshPropValue(propName) {
			let prop = this.props.find(p => p.name === propName)
			if (prop) {
				prop.value = this.cell.props[propName]
			}
		},
		createProps() {
			let props = []
			let cell = this.cell
			if (cell.def) {
				cell.def.props.forEach(p => {
					let prop = { ...p }
					prop.value = cell.props[p.name]
					props.push(prop)
				})
			}
			this.props = props
		},
		removeInvalidNameCharacters() {
			if (!this.cell) return
			const invalidName = /[^a-z0-9-_ ]/gi
			this.layoutName = this.layoutName.replace(invalidName, '')
			this.cellName = this.cellName.replace(invalidName, '')
		},
		renameCell(cell) {
			cell = cell || this.cell
			if (this.cellName !== cell.name) {
				try {
					cell.rename(this.cellName)
				} catch (e) {
					showAlert(e.message)
					this.cellName = cell.name
				}
			}
		},
		renameLayout(cell) {
			cell = cell || this.cell
			if (this.layoutName !== cell.layout.name) {
				try {
					cell.layout.rename(this.layoutName)
				} catch (e) {
					showAlert(e.message)
					this.layoutName = cell.layout.name
				}
			}
		},
		saveProps() {
			if (!this.cell) return
			this.removeInvalidNameCharacters()
			this.renameCell()
			this.renameLayout()

			this.props.forEach(p => {
				if (p.type === 'list') {
					if (!Array.isArray(p.value)) {
						p.value = p.value.split(',')
					}
					// remove dupes
					p.value = Array.from(new Set(p.value))
				}
				this.cell.props[p.name] = p.value
			})

			if (this.cell.type === 'Table') {
				this.cell.configureTable()
			}

			if (this.isPdfTemplateRoot) {
				state.set.templates.forEach(t => (t.imageCommentColumns = this.cell.template.imageCommentColumns))
			}

			return false
		},
		addNewWidget() {
			let layout = state.createNewLayout()
			layout.group = this.cell.type
		},
		async deleteWidget(widgetName) {
			let r = await showConfirm(`Are you sure you want to delete ${widgetName}?`)
			if (r !== true) return
			let template = this.cell.template
			const openTabIndex = state.openLayouts.findIndex(l => l.name === widgetName)
			if (openTabIndex >= 0) state.openLayouts.splice(openTabIndex, 1)
			let layout = state.editableLayouts.find(l => l.name === widgetName)
			template.removeLayout(layout)
		},
		async reset() {
			let r = await showConfirm(`Are you sure you want to reset the ${this.title} layout?`)
			if (!r) return
			state.closeLayout(this.cell.layout)
			let layout = this.cell.template.resetLayout(this.cell.layout.name)
			state.selectLayout(layout)
		},
		useSignatureImage() {
			this.cell.props.binding = '[Signature]'
			this.refreshPropValue('binding')
		},
		useLogoImage() {
			this.cell.props.binding = '[Logo]'
			this.refreshPropValue('binding')
		},
		deleteComp() {
			if (confirm(`Are you sure you want to delete ${this.title}?`)) {
				state.closeLayout(this.cell.layout)
				state.template.removeLayout(this.cell.layout)
			}
		},
		async selectWatermarkImage() {
			let url = await openClinicImagesDlg(this.cell.template.watermarkUrl)
			this.cell.template.watermarkUrl = url
			this.saveProps()
		},
		async browseImages(prop) {
			let url = await openClinicImagesDlg(prop.value)
			prop.value = url
			this.saveProps()
		},
	},
	template: '#layout-pane',
}
