<template>
	<div class="padding-editor">
		<input
			v-if="showOneInput"
			v-model.number="singlePaddingValue"
			type="number"
			min="0"
			class="input"
		/>
		<table v-else style="font-size: 0.9em;">
			<tr>
				<td><label>Top</label></td>
				<td>
					<input v-model.number="padding[0]" type="number" min="0" class="input" />
				</td>
			</tr>
			<tr>
				<td><label>Right</label></td>
				<td>
					<input v-model.number="padding[1]" type="number" min="0" class="input" />
				</td>
			</tr>
			<tr>
				<td><label>Bottom</label></td>
				<td>
					<input v-model.number="padding[2]" type="number" min="0" class="input" />
				</td>
			</tr>
			<tr>
				<td><label>Left</label></td>
				<td>
					<input v-model.number="padding[3]" type="number" min="0" class="input" />
				</td>
			</tr>
		</table>

		<ast-checkbox v-model="showOneInput">
			<span>Use equal padding on all sides</span>
		</ast-checkbox>
	</div>
</template>

<script>
import AstCheckbox from '@components/Checkbox.vue'
import state from '@services/reportService'

export default {
	name: 'PaddingEditor',
	components: { AstCheckbox },
	props: {
		value: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {
			state,
			showOneInput: true,
		}
	},
	computed: {
		padding: {
			get() {
				return this.value
			},
			set(padding) {
				this.$emit('input', padding)
			},
		},
		singlePaddingValue: {
			get() {
				return this.padding[0]
			},
			set(padding) {
				this.padding = [padding, padding, padding, padding]
			},
		},
	},
	watch: {
		'state.activeCell': {
			handler() {
				// show all inputs if padding values are not all the same
				this.showOneInput = this.value.every(p => p === this.value[0])
			},
			immediate: true,
		},
		showOneInput() {
			// if user checks the box, set all padding values to match the first value
			if (this.showOneInput) this.singlePaddingValue = this.padding[0]
		},
	},
}
</script>

<style lang="scss">
.padding-editor {
	.padding-row {
		display: flex;
		align-items: center;
	}
}
</style>
