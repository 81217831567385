




































import { METHODS } from '../binding'
import { ContextMeta } from '../context'
import { TemplateSet, Cell } from '../classes'
import reportService from '@services/reportService'

class Suggestion {
	method?: string
	bindPath?: BindPath
	description?: string

	constructor({ method = undefined, bindPath = undefined, description = undefined }) {
		this.method = method
		this.bindPath = bindPath
		this.description = description
	}
	get name(): string {
		if (this.method) return this.method
		return this.bindPath.name
	}
	get insertText(): string {
		if (this.method) return this.name + '('
		let text = this.bindPath.path
		if (this.bindPath.hasChildren) text += '.'
		return text
	}
	get icon(): string {
		if (this.method) return 'function'
		if (this.bindPath && this.bindPath.hasChildren) return 'cube-outline'
		if (this.bindPath.cell && this.bindPath.cell.type) {
			const tool = reportService.tools.find(t => t.name === this.bindPath.cell.type)
			return tool ? tool.icon : 'window-maximize'
		}
		return 'file-document-box-outline'
	}
}

class BindPath {
	name: string
	children?: BindPath[] = []
	parent?: BindPath = null
	cell?: Cell

	constructor(name: string, parent: BindPath = null, cell: Cell = null) {
		this.name = name
		this.parent = parent
		this.cell = cell
		if (parent) {
			parent.children.push(this)
		}
	}

	get path(): string {
		let result = ''
		let p: BindPath = this
		while (!p.isRoot) {
			if (result) {
				result = `${p.name}.${result}`
			} else {
				result = p.name
			}

			p = p.parent
		}
		return result
	}
	get hasChildren() {
		return this.children.length > 0
	}
	get isRoot() {
		return !this.parent
	}
}

function isContextItemAllowed(meta) {
	if (!meta) return true
	if (meta.sets && !meta.sets.includes(reportService.set.type)) return false
	if (meta.templates && !meta.templates.includes(reportService.template.type)) return false
	if (meta.layouts && !meta.layouts.includes(reportService.selLayout.name)) return false
	return true
}

function buildContextItem(obj: any, parent?: BindPath) {
	for (let prop in obj.items) {
		const meta = ContextMeta[parent.name] && ContextMeta[parent.name].items[prop]
		if (!isContextItemAllowed(meta)) continue
		let item = new BindPath(prop, parent)
		let value = obj[prop]
		if (value instanceof Object) {
			buildContextItem(value, item)
		}
	}
}

function buildBindPath(cell: Cell, parent?: BindPath) {
	const isBindable = cell.layout.bindableControls.includes(cell)
	let childParent = isBindable ? new BindPath(cell.name, parent, cell) : parent

	if (!cell.primitive) {
		let sub = cell.template.layouts.find(l => l.name === cell.type)
		if (sub) {
			sub.controls.forEach(c => buildBindPath(c, childParent))
		}
	}
}

function buildBindPaths(term?: string): BindPath {
	let activePath = new BindPath('root')
	for (let prop in ContextMeta) {
		const meta = ContextMeta[prop]
		if (!isContextItemAllowed(meta)) continue
		buildContextItem(ContextMeta[prop], new BindPath(prop, activePath))
	}

	reportService.set.templates.forEach(t => {
		// cannot bind to Response fields on Request template
		if (reportService.template.type === 'Request' && t.type === 'Response') return
		// cannot bind to PDF Report from other templates
		if (t.type === 'PDF Report') return
		// only add template path if template's root layout or widgets have bindable controls
		const layouts = t.layouts.filter(l => l !== t.root && !!l.group)
		if ([t.root, ...layouts].some(l => l.bindableControls.length)) {
			// do not nest controls for current template
			const isCurrentTemplate = reportService.template.type === t.type
			let templatePath = isCurrentTemplate ? activePath : new BindPath(t.type, activePath)
			// exclude active cell from suggestions
			const isNotCurrentCell = c => c !== reportService.activeCell
			t.root.controls.filter(isNotCurrentCell).forEach(c => buildBindPath(c, templatePath))
			layouts.forEach(w => {
				// nest widget controls under widget name
				let widgetPath = new BindPath(w.name, templatePath)
				w.controls.filter(isNotCurrentCell).forEach(c => buildBindPath(c, widgetPath))
			})
		}
	})
	const termPaths = term.split('.')
	if (termPaths.length === 1) return activePath
	for (let i = 0; i < termPaths.length; i++) {
		const matchingPath = activePath.children.find(p => p.name === termPaths[i])
		if (!matchingPath) break
		activePath = matchingPath
	}
	return activePath
}

export default {
	name: 'BindingSuggest',
	props: {
		top: {
			type: Number,
			default: 0,
		},
		left: {
			type: Number,
			default: 0,
		},
		term: {
			type: String,
			default: '',
		},
		showMethodsOnly: {
			type: Boolean,
			default: false,
		},
		showValuesOnly: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			highlightedIndex: 0,
		}
	},
	computed: {
		activePath() {
			return buildBindPaths(this.term.trim())
		},
		searchTerm() {
			// e.g. change 'Report.ReferredBy.Name' => 'Name'
			if (!this.term) return ''
			const paths = this.term.trim().split('.')
			return paths[paths.length - 1].replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // escape for regex
		},
		suggestions() {
			const allSuggestions = []
			if (!this.showMethodsOnly)
				allSuggestions.push(...this.activePath.children.map(p => new Suggestion({ bindPath: p })))
			if (!this.showValuesOnly && this.activePath.isRoot) {
				const methodSuggestions = Object.keys(METHODS).map(
					m =>
						new Suggestion({
							method: m,
							description: this.showMethodsOnly ? METHODS[m].description : undefined,
						})
				)
				allSuggestions.push(...methodSuggestions)
			}
			const suggestionSort = (a, b) => {
				const startsWithTerm = s => new RegExp('^' + this.searchTerm, 'i').test(s.name)
				if (startsWithTerm(a) && !startsWithTerm(b)) return -1
				if (startsWithTerm(b) && !startsWithTerm(a)) return 1
				if (a.bindPath && !b.bindPath) return -1
				if (b.bindPath && !a.bindPath) return 1
				if (a.bindPath && a.bindPath.hasChildren && (b.bindPath && !b.bindPath.hasChildren))
					return -1
				if (b.bindPath && b.bindPath.hasChildren && (a.bindPath && !a.bindPath.hasChildren))
					return 1
				if (a.name.toLowerCase() < b.name.toLowerCase()) return -1
				return 0
			}

			if (!this.searchTerm) return allSuggestions.sort(suggestionSort)

			const filteredSuggestions = allSuggestions.filter(s =>
				new RegExp(this.searchTerm, 'i').test(s.name)
			)
			return filteredSuggestions.sort(suggestionSort)
		},
	},
	watch: {
		top() {
			this.moveMenu()
		},
		left() {
			this.moveMenu()
		},
		suggestions() {
			this.highlightedIndex = 0
		},
	},
	mounted() {
		this.moveMenu()
		document.addEventListener('keydown', this.onKeydown)
		this.$once('hook:beforeDestroy', () => {
			document.removeEventListener('keydown', this.onKeydown)
		})
	},
	methods: {
		onKeydown(e) {
			const list = this.$refs.list
			if (list && list.style && list.style.display === 'none') return
			if (!['ArrowDown', 'ArrowUp', 'Escape', 'Enter', 'Tab'].includes(e.key)) return
			e.preventDefault()
			e.stopPropagation()
			if (e.key === 'Escape') return this.$emit('close')
			if (['Enter', 'Tab'].includes(e.key))
				return this.select(this.suggestions[this.highlightedIndex])
			let newIndex = this.highlightedIndex
			newIndex += e.key === 'ArrowDown' ? 1 : -1
			if (newIndex >= this.suggestions.length) newIndex = 0
			if (newIndex < 0) newIndex = this.suggestions.length - 1
			this.highlightedIndex = newIndex
			this.$refs.suggestions[newIndex].scrollIntoView({ block: 'nearest' })
		},
		moveMenu() {
			const list = this.$refs.list
			if (!list || !list.style) return
			list.style.left = this.left + 'px'
			list.style.top = this.top + 'px'
			this.$nextTick(() => {
				const offScreenX =
					Math.max(0, list.getBoundingClientRect().right - window.innerWidth) ||
					Math.min(0, list.getBoundingClientRect().left)
				const offScreenY =
					Math.max(0, list.getBoundingClientRect().bottom - window.innerHeight) ||
					Math.min(0, list.getBoundingClientRect().top)
				if (offScreenX) list.style.left = this.left - offScreenX + 'px'
				if (offScreenY) list.style.top = this.top - offScreenY + 'px'
			})
		},
		select(suggestion?: Suggestion) {
			if (suggestion) this.$emit('select', suggestion.insertText)
			if (
				this.showValuesOnly ||
				!suggestion ||
				!suggestion.bindPath ||
				!suggestion.bindPath.hasChildren
			)
				this.$emit('close')
		},
	},
}
