<template>
	<span style="display: inline-flex;">
		<toolbar-button title="Undo (Ctrl+Z)" icon="undo" label="Undo" :disabled="!canUndo" @click.native="undo" />
		<toolbar-button
			title="Redo (Ctrl+Y / Shift+Ctrl+Z)"
			icon="redo"
			label="Redo"
			:disabled="!canRedo"
			@click.native="redo"
		/>
	</span>
</template>

<script>
import state from '@services/reportService'
import ToolbarButton from '@components/ToolbarButton.vue'

export default {
	name: 'UndoRedo',
	components: {
		ToolbarButton,
	},
	data() {
		return {
			state,
			isUndoingOrRedoing: false,
			isThrottled: false,
		}
	},
	computed: {
		canUndo() {
			return state.undoStack.length
		},
		canRedo() {
			return state.redoStack.length
		},
	},
	watch: {
		'state.stateSnapshot': {
			handler(newState, oldState) {
				const THROTTLE_DURATION_MS = 1000
				if (this.isUndoingOrRedoing || !oldState) return
				if (newState.setJson === oldState.setJson) return
				if (newState.setId !== oldState.setId) return state.resetStateStorage()
				if (newState.setStatus !== oldState.setStatus) return state.resetStateStorage()
				if (!this.isThrottled || state.undoStack.length === 1) {
					state.storeState(oldState)
					this.isThrottled = true
					setTimeout(() => (this.isThrottled = false), THROTTLE_DURATION_MS)
				} else {
					state.replaceStoredState(oldState)
				}
			},
			deep: true,
		},
	},
	mounted() {
		document.addEventListener('keydown', this.onKeydown)
	},
	beforeDestroy() {
		document.removeEventListener('keydown', this.onKeydown)
	},
	methods: {
		onKeydown(e) {
			if (!e.ctrlKey && !e.metaKey) return
			const yKey = 89
			const zKey = 90
			if (e.keyCode === yKey && this.canRedo) {
				// Ctrl+Y
				this.redo()
			} else if (e.shiftKey && e.keyCode === zKey && this.canUndo) {
				// Shift+Ctrl+Z
				this.redo()
			} else if (e.keyCode === zKey && this.canUndo) {
				// Ctrl+Z
				this.undo()
			}
			e.stopPropagation()
		},
		undo() {
			if (!this.canUndo || this.isUndoingOrRedoing) return
			this.isUndoingOrRedoing = true
			state.undo()
			this.$nextTick(() => {
				this.isUndoingOrRedoing = false
			})
		},
		redo() {
			if (!this.canRedo || this.isUndoingOrRedoing) return
			this.isUndoingOrRedoing = true
			state.redo()
			this.$nextTick(() => {
				this.isUndoingOrRedoing = false
			})
		},
	},
}
</script>
