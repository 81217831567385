





















import { Binding, Expression } from '../binding'
import BindingSuggest from '@reporting/components/BindingSuggest.vue'
let updateDebounce

export default {
	name: 'BindingEditor',
	components: {
		BindingSuggest,
	},
	model: {
		prop: 'binding',
		event: 'input',
	},
	props: {
		binding: {
			type: Binding,
			required: true,
		},
		insertText: {
			type: String,
			default: '',
		},
		readOnly: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			activeNode: undefined,
			showSuggest: false,
			suggestTop: 32,
			suggestLeft: 0,
			storedAnchorOffset: 0,
			storedFocusOffset: 0,
		}
	},
	watch: {
		insertText() {
			if (this.insertText) this.insertSuggestion(this.insertText)
			this.$emit('insert')
		},
		binding() {
			this.$refs.editor.innerText = this.binding.text
			this.update()
		},
	},
	mounted() {
		if (this.$refs.editor) this.$refs.editor.innerText = this.binding.text
		this.update()
	},
	methods: {
		update(e) {
			clearTimeout(updateDebounce)
			updateDebounce = setTimeout(() => {
				const selection = document.getSelection()
				if (!this.readOnly && selection && selection.anchorNode && selection.anchorNode.nodeType === Node.TEXT_NODE) {
					this.storeSelection(selection)
					this.positionSuggest(selection)
				}
				this.renderBinding()
				if (!this.readOnly && selection) {
					this.restoreSelection()
					this.binding.parse(this.$refs.editor.innerText)
				}
				if (e) this.showSuggest = !!this.$refs.editor.innerText.trim() // only show suggest after typing something
			}, 50)
		},
		storeSelection({ anchorOffset, focusOffset, anchorNode, focusNode }) {
			const textNodes = getTextNodes(this.$refs.editor)
			this.storedAnchorOffset = sumPrecedingNodes(anchorNode) + anchorOffset
			this.storedFocusOffset = sumPrecedingNodes(focusNode) + focusOffset

			function sumPrecedingNodes(node: Node): number {
				const nodeIndex = textNodes.indexOf(node)
				const precedingNodes = textNodes.filter((n, i) => i < nodeIndex)
				return precedingNodes.reduce((a, n) => a + n.nodeValue.length, 0)
			}
		},
		positionSuggest(selection) {
			const range = selection.getRangeAt(0)
			const cursorRange = document.createRange()
			cursorRange.setStart(selection.anchorNode, range.startOffset)
			cursorRange.setEnd(selection.anchorNode, range.startOffset)
			range.collapse(true)
			const cursorMarker = document.createElement('span')
			range.insertNode(cursorMarker)
			this.suggestTop = cursorMarker.offsetTop + 20
			this.suggestLeft = cursorMarker.offsetLeft
			cursorMarker.remove()
		},
		renderBinding() {
			let text = this.$refs.editor.innerText
			this.binding._parse(text)
			let html = ''
			const getExpressions = (e: Expression) => [e].concat(...e.args.map(e => getExpressions(e)))
			const expressions = getExpressions(this.binding.expression) // flattened array
			expressions.forEach((e, i) => {
				const expressionStart = text.indexOf(e.term)
				if (expressionStart < 0) html += text
				else {
					const precedingText = text.slice(0, expressionStart)
					html += precedingText
					const title = e.errors.length ? e.errors.join('\n') : e.type
					html += `<span class="${e.classList.join(' ')} title="${title}" data-is-expression>${e.term}</span>`
					text = text.slice(expressionStart + e.term.length)
					if (i === expressions.length - 1) html += text
				}
			})
			this.$refs.editor.innerHTML = html
		},
		async insertSuggestion(suggestion) {
			const selection = document.getSelection()
			const isEmpty = !getTextNodes(this.$refs.editor).length
			if (isEmpty || !this.activeNode || !this.activeNode.isConnected) {
				this.$refs.editor.innerText += suggestion
				const textNodes = getTextNodes(this.$refs.editor)
				const lastNode = textNodes[textNodes.length - 1]
				selection.setBaseAndExtent(lastNode, lastNode.length, lastNode, lastNode.length)
			} else {
				this.activeNode.nodeValue = suggestion
				selection.setBaseAndExtent(this.activeNode, this.activeNode.length, this.activeNode, this.activeNode.length)
			}
			this.update()
			this.showSuggest = true
		},
		restoreSelection() {
			const selection = document.getSelection()
			const textNodes = getTextNodes(this.$refs.editor)
			let anchorNode = this.$refs.editor
			let anchorOffset = 0
			let focusNode = this.$refs.editor
			let focusOffset = 0
			let currentOffset = 0
			textNodes.forEach(node => {
				const nodeEndOffset = currentOffset + node.nodeValue.length
				if (currentOffset <= this.storedAnchorOffset && this.storedAnchorOffset <= nodeEndOffset) {
					anchorNode = node
					anchorOffset = this.storedAnchorOffset - currentOffset
				}
				if (currentOffset <= this.storedFocusOffset && this.storedFocusOffset <= nodeEndOffset) {
					focusNode = node
					focusOffset = this.storedFocusOffset - currentOffset
					const isExpression = focusNode.parentElement.dataset.isExpression !== undefined
					if (isExpression) this.activeNode = focusNode // can be replaced by insertSuggestion
				}
				currentOffset = nodeEndOffset
			})
			selection.setBaseAndExtent(anchorNode, anchorOffset, focusNode, focusOffset)
		},
	},
}

function getTextNodes(element: Node) {
	const textNodes = []
	Array.from(element.childNodes).forEach(node => {
		switch (node.nodeType) {
			case Node.TEXT_NODE:
				textNodes.push(node)
				break
			case Node.ELEMENT_NODE:
				textNodes.splice(textNodes.length, 0, ...getTextNodes(node))
				break
		}
	})
	return textNodes
}
