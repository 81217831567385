import { showAlert } from '@/dialogs/MessageDlg.vue'
import { Template } from '@/reporting/classes'
import state from '@services/reportService'

export default {
	props: {
		dataflow: {
			type: String,
			required: true,
		},
		partner: {
			type: String,
			required: true,
		},
		template: {
			type: String,
			required: true,
		},
	},
	data() {
		return {
			state,
			controls: [],
			fields: [],
			selItem: null,
			layout: null,
			loadedTemplate: null,
			loadedPartner: null,
		}
	},
	computed: {
		key() {
			return `${this.partner}_${this.template}`
		},
		layouts() {
			const template: Template = this.state.set.templates.find(t => t.type === this.template)
			let selectorName = template.type === 'Response' ? 'Response Selector' : 'Request Selector'
			return template.layouts.filter(l => l.group === selectorName)
		},
	},
	watch: {
		key: {
			handler(key, prevKey) {
				if (prevKey) this.updateTemplateFieldMap()
				this.loadSelectedLayout()
				this.loadTemplateFieldMap()
				this.loadedPartner = this.partner
				this.loadedTemplate = this.template
			},
			immediate: true,
		},
		layout() {
			this.loadTemplateFieldMap()
		},
	},
	beforeDestroy() {
		this.updateTemplateFieldMap()
	},
	methods: {
		loadSelectedLayout() {
			if (this.dataflow === 'incoming') {
				const template = state.set.templates.find(t => t.type === this.template)
				let first = this.layouts[0]
				let result = template.partnerLayoutMap[this.partner] || null
				if (!result && first) {
					result = first.name
				}

				this.layout = result
			} else this.layout = null
		},
		loadTemplateFieldMap() {
			const template = state.set.templates.find(t => t.type === this.template)
			const allControls = []

			let validLayoutNames = {}
			let validLayouts = template.layouts.filter(l => {
				if (l.isRoot) return true
				// exclude any Image Comment & Addendum controls
				else if (!l.group) return false
				else return !this.layout || l.name === this.layout
			})

			validLayouts.forEach(l => {
				validLayoutNames[l.name] = true
				allControls.push(...l.controls)
			})

			this.fields = state.fieldMap[this.partner]
				.filter(f => f.template === this.template && f.dataflow === this.dataflow && f.display)
				.map(f => {
					// load field controls from existing template field map
					const mappedControlPaths = template.partnerFieldMap[f.name] || []
					const controls = mappedControlPaths
						.map(p => allControls.find(c => c.path === p))
						.filter(c => !!c && validLayoutNames[c.layout.name]) // do not retain mappings to invalid control paths
					return { ...f, controls }
				})
			const isValidType = c => this.fields.some(f => f.fieldTypes.includes(c.type))
			this.controls = allControls.filter(isValidType)
		},
		selectItem(item: [IPartnerField, ICell]) {
			if (this.selItem === item) this.selItem = null
			else this.selItem = item
		},
		getControlIcon(control: ICell): string {
			const tool = state.tools.find(t => t.name === control.type)
			if (!tool) return
			return tool.icon
		},
		onDragStart(item: [IPartnerField, ICell], e: DragEvent) {
			this.selItem = item
			e.dataTransfer.setData('text', '') // not used, but required for Firefox
		},
		onDragEnd() {
			// clear selected item in case drop is not successful
			this.$nextTick(() => (this.selItem = null))
		},
		onDragOver(e: DragEvent) {
			this.togglePaneSelected(e, true)
		},
		onDragLeave(e: DragEvent) {
			this.togglePaneSelected(e, false)
		},
		onDrop(item: [IPartnerField, ICell], e) {
			this.togglePaneSelected(e, false)
			if (this.dataflow === 'incoming') {
				// dropping onto a control
				this.addControlToField(this.selItem, item)
			} else {
				// dropping onto a field
				this.addControlToField(item, this.selItem)
			}
		},
		togglePaneSelected(e: DragEvent, isOver: boolean) {
			e.dataTransfer.dropEffect = 'move'
			if (!event.target) return
			const method = isOver ? 'add' : 'remove'
			const target = event.target as HTMLDivElement
			const pane = target.closest('.item-bucket') as HTMLDivElement
			if (pane) pane.classList[method]('selected')
		},
		addControlToField(field: IPartnerField, control: ICell) {
			if (!field || !control || field.controls.includes(control)) return
			if (this.dataflow === 'incoming' && this.fields.some(f => f.controls.includes(control))) return // enforce 1-to-1
			if (field.fieldTypes && !field.fieldTypes.includes(control.type)) {
				showAlert(`${field.display} can only be mapped to ${field.fieldTypes.join(',')} controls.`)
				return
			}
			this.fields.forEach(f => this.removeControlFromField(f, control))
			if (this.dataflow === 'incoming') field.controls = [] // enforce 1-to-1
			field.controls.push(control)
			this.selItem = null
		},
		removeControlFromField(field: IPartnerField, control: ICell) {
			let idx = field.controls.indexOf(control)
			if (idx >= 0) field.controls.splice(idx, 1)
		},
		updateTemplateFieldMap() {
			const template = state.set.templates.find(t => t.type === this.loadedTemplate)
			let newMap = { ...template.partnerFieldMap }
			this.fields.forEach(f => {
				newMap[f.name] = f.controls.map(i => i.path)
			})
			template.partnerFieldMap = newMap

			let layoutMap = template.partnerLayoutMap
			layoutMap[this.loadedPartner] = this.layout
			template.partnerLayoutMap = layoutMap
		},
	},
}
