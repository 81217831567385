<template>
	<div>
		<ast-checkbox v-for="item in prop.options" :key="item" v-model="values[item]" @input="save()">
			{{ item }}
		</ast-checkbox>
	</div>
</template>

<script>
import AstCheckbox from '@components/Checkbox.vue'
export default {
	name: 'ReqFields',
	components: {
		AstCheckbox,
	},
	props: ['prop'],
	data() {
		return {
			values: null,
		}
	},
	created() {
		let values = {}
		this.prop.options.forEach(p => {
			values[p] = this.prop.value.includes(p)
		})
		this.values = values
	},
	methods: {
		save() {
			let prop = this.prop
			prop.value = prop.options.filter(p => this.values[p])
			this.$emit('input')
		},
	},
}
</script>
